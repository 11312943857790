import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Text, Wrap, ButtonDelete } from './styled';
import Context from '../Context';
import useI18nNavigation from '../../../../hooks/use-i18n-navigation';
import useBasket from '../../../../hooks/use-basket';
import { Link } from '../styled';
import deleteImg from '../../../../images/basket/delete.svg';

function NotAvailable({ value }) {
  const { item } = useContext(Context);
  const { removeItem } = useBasket();
  const navigate = useI18nNavigation();

  const onRemove = () => {
    removeItem(item);

    navigate('/shop');
  };

  return (
    <Wrap>
      <ButtonDelete
        src={deleteImg}
        onClick={() => removeItem(item)}
        alt="delete"
      />
      <Text>
        <FormattedMessage
          id="basket.no_longer_available"
          values={{ val: value }}
        />
      </Text>
      {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
      <Link href="#" onClick={onRemove}>
        <FormattedMessage id="basket.see_alternative" />
      </Link>
    </Wrap>
  );
}

NotAvailable.propTypes = {
  value: PropTypes.string.isRequired,
};

export default NotAvailable;
