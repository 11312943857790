import styled from 'styled-components';
import { colors, mediaMax } from '../../../ui/common';
import { Text } from '../styled';

export const ButtonDelete = styled.img`
  &:hover {
    cursor: pointer;
  }
`;

export const ContentItemWrap = styled.div`
  padding: 0 20px;
  ${mediaMax.phone`
    padding: 0 10px;
  `};
`;

export const ContentItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: ${(props) => (props.iter > 0 ? '30px' : 0)};
`;

export const ContentItemLeft = styled.div`
  flex-basis: 83px;
`;

export const ContentItemCenter = styled.div``;

export const ContentItemRight = styled.div`
  margin-left: auto;
  padding-bottom: 25px;
`;

export const ContentItemBottom = styled(ContentItem)`
  margin-top: 19px;
`;

export const ContentControlLeft = styled.div`
  flex-basis: 75px;
`;

export const ContentControlCenter = styled.div``;

export const ContentControlRight = styled.div`
  margin-left: auto;
`;

export const ControlText = styled(Text)`
  margin-top: 5px;
`;

export const ControlWrapText = styled(Text)`
  margin-top: 0;
`;

export const CheckboxCartridgeWrap = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: baseline;
`;

export const Divider = styled.div`
  opacity: 0.4;
  background: ${colors.color05};
  width: 100%;
  height: 1px;
  margin: 27px auto 0;
  transform: scaleY(0.99);
`;

export const DividerItem = styled(Divider)`
  width: 92%;
`;

export const CartridgeBlock = styled.div`
  margin-top: 37px;
`;

export const Link = styled.a`
  display: block;
  margin-top: 12px;
  font-weight: normal;
  font-size: 14px;
  text-align: center;
  text-decoration-line: underline;
  color: ${colors.color01};
  cursor: pointer;
`;
