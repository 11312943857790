import styled from 'styled-components';
import { Text } from '../styled';
import { colors, colorsBar } from '../../../ui/common';

export const CounterFlexContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 19px;
`;

export const CounterText = styled.p`
  font-weight: normal;
  font-size: 12px;
  flex-basis: 50%;
  margin-right: 10px;
  line-height: 1.4;
`;

export const CoolPrivilegeLinkText = styled.span`
  text-decoration: underline;
  cursor: pointer;
`;

export const CounterPrice = styled(Text)`
  margin-left: auto;
  margin-top: 0;
`;

export const CheckboxCoolPrivilegeWrap = styled.label`
  cursor: pointer;
  display: flex;
  align-items: center;
`;

export const CheckBoxCoolPrivilegeText = styled.span`
  font-size: 12px;
  transform: translateY(1px);
  color: ${(props) =>
    props.chillBlueTheme ? colorsBar.blueDark : colors.color01};
  margin-left: 8px;
  user-select: none;
`;
