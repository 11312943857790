import styled from 'styled-components';
import { colors, mediaMax } from '../../ui/common';

export const Container = styled.div`
  display: ${(props) => (props.visible ? 'block' : 'none')};
  position: fixed;
  top: 0;
  right: 0;
  height: 100%;
  max-width: 340px;
  padding: 26px 47px 50px;
  ${mediaMax.iphone5`
    padding: 26px 26px 50px;
  `}
  ${mediaMax.phone`
    max-width: none;
    width: 100%;
    box-sizing: border-box;
  `}
  color: ${colors.color05};
  font-size: 14px;
  background: #fafafa;
  z-index: 100;
  overflow-y: auto;
`;

export const CloseButton = styled.img`
  &:hover {
    cursor: pointer;
  }
`;

export const Title = styled.div`
  font-weight: 600;
  font-size: 14px;
  color: ${colors.color01};
  ${(props) => props.error && `color: ${colors.error};`}
`;

export const Text = styled.div`
  margin-top: 10px;
  font-weight: normal;
  font-size: 12px;
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const HeaderTitle = styled(Title)`
  display: inline;
  font-size: 14px;
`;

export const HeaderTitleItem = styled.span`
  font-weight: normal;
  font-size: 14px;
  margin-left: 4px;
  color: ${colors.color01};
`;

export const Content = styled.div`
  min-width: 340px;
  margin-top: 45px;
  max-height: 269px;
  overflow-y: auto;
  padding: ${(props) => (props.scrollable ? '0px 10px' : '')};
  ${mediaMax.phone`
    min-width: auto;
  `}'
`;

export const PriceBlock = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 27px 0 18px;
`;

export const PriceText = styled.div`
  font-weight: 600;
  font-size: 14px;
  color: ${colors.color05};
`;

export const ShippingText = styled.div`
  margin-top: 28px;
  font-size: 12px;
  font-weight: normal;
  color: ${colors.color01};
`;

export const Divider = styled.div`
  opacity: 0.4;
  background: ${colors.color05};
  width: 100%;
  height: 1px;
  margin: 27px auto 0;
  transform: scaleY(0.99);
`;

export const ExtraDiscountText = styled.div`
  margin-top: 28px;
  font-size: 12px;
  font-weight: normal;
  color: ${colors.color01};
`;

export const NoItems = styled.p`
  color: ${colors.accent};
`;

export const ShopButton = styled.a`
  text-decoration: underline;
`;
