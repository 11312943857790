import styled from 'styled-components';

export const Wrap = styled.div`
  display: flex;
  margin-top: 26px;
`;

export const Img = styled.img`
  margin-right: 10px;
  cursor: pointer;
`;

export const Text = styled.div``;

export const Amount = styled.div`
  flex-grow: 3;
  text-align: right;
`;
