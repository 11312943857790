import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import minusImg from '../images/basket/minus.svg';
import plusImg from '../images/basket/plus.svg';
import { colors } from './common';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
`;

const Button = styled.img`
  cursor: pointer;
`;

const Title = styled.div`
  width: ${(props) => (props.mobile ? '30px' : '50px')};
  text-align: center;
  color: ${colors.color05};
  ${(props) => props.error && `color: ${colors.error};`}
`;

function ButtonCounter({
  count,
  minimumCount,
  onUpdateCount,
  mobile,
  enablePlus,
  error,
}) {
  const updateCount = (newCount) => {
    if (onUpdateCount) onUpdateCount(newCount);
  };

  const onRemove = () => {
    updateCount(Math.max(minimumCount, count - 1));
  };

  const onAdd = () => {
    if (!enablePlus) return;

    updateCount(count + 1);
  };

  return (
    <Wrapper>
      <Button src={minusImg} alt="icon" onClick={onRemove} />
      <Title error={error} mobile={mobile}>
        {count}
      </Title>
      <Button src={plusImg} alt="icon" onClick={onAdd} />
    </Wrapper>
  );
}

ButtonCounter.propTypes = {
  count: PropTypes.number,
  mobile: PropTypes.bool,
  onUpdateCount: PropTypes.func,
  minimumCount: PropTypes.number,
  enablePlus: PropTypes.bool,
  error: PropTypes.bool,
};

ButtonCounter.defaultProps = {
  count: 1,
  mobile: false,
  onUpdateCount: null,
  minimumCount: 1,
  enablePlus: true,
  error: false,
};

export default ButtonCounter;
