import isFinite from 'lodash/isFinite';

const formatPrice = (price) => {
  let strPrice = '0.0';
  if (isFinite(price)) {
    strPrice = price.toFixed(2);
  }
  return strPrice;
};

export default formatPrice;
