import styled, { css } from 'styled-components';
import { secondary } from '../../../ui/buttonDefault';
import { colors } from '../../../ui/common';

export const Main = styled.div`
  position: relative;
`;

export const Wrap = styled.div`
  padding: 18px;
  background: #ffffff;
  box-shadow: 0px 7px 20px rgba(0, 0, 0, 0.07);
  border-radius: 6px;
  margin-bottom: 50px;
`;

export const Top = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 32px;
`;

export const TopLeft = styled.div`
  padding: 0px 14px;
`;

export const TopRight = styled.div`
  line-height: 180%;
`;

export const Title = styled.div`
  font-weight: 600;
  font-size: 14px;
  color: ${colors.color05};
`;

export const Desc = styled.div`
  width: 200px;
  font-weight: normal;
  font-size: 12px;
  color: ${colors.color01};
`;

export const Bottom = styled.div`
  width: 100%;
`;

export const Button = styled.button`
  ${secondary};
  display: block;
  width: 100%;
  border-radius: 5px;
  height: 38px;
  line-height: 38px;
`;

const arrow = css`
  position: absolute;
  top: 50%;
  cursor: pointer;
`;

export const ArrowLeft = styled.div`
  ${arrow};
  left: -35px;
  transform: rotateY(0deg);
`;

export const ArrowRight = styled.div`
  ${arrow};
  right: -35px;
  transform: rotateY(180deg);
`;
