import styled from 'styled-components';
import { colors } from '../../../../ui/common';

export const Wrap = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px;
  background-color: #f1f1f1;
`;

export const Text = styled.div`
  font-weight: 600;
  font-size: 18px;
  line-height: 165%;
  text-align: center;
  margin: 0 auto;
  max-width: 290px;
  color: ${colors.color01};
`;

export const ButtonDelete = styled.img`
  display: block;
  margin-left: auto;
  margin-bottom: 10px;
  &:hover {
    cursor: pointer;
  }
`;
