import React, { useState, useContext } from 'react';
import { FormattedMessage } from 'react-intl';
import { Text, Wrap, Link, Root, Main } from './styled';
import Context from '../Context';
import Stock from '../Stock';
import useBasket from '../../../../hooks/use-basket';

function ItemLeft() {
  const { setItemCount } = useBasket();
  const { id, realCount, availableQuantity } = useContext(Context);
  const [visible, setVisible] = useState(true);

  const onClose = () => {
    setVisible(false);
    setItemCount({ id, count: availableQuantity });
  };

  return (
    visible && (
      <Main>
        <Root>
          <Wrap>
            <Text>
              <Stock value={realCount} />
            </Text>
            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
            <Link href="#" onClick={onClose}>
              <FormattedMessage
                id="basket.okay_set_to"
                values={{ val: availableQuantity }}
              />
            </Link>
          </Wrap>
        </Root>
      </Main>
    )
  );
}

export default ItemLeft;
