import React, { useState, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { useSelector, useDispatch } from 'react-redux';
import {
  ErrorText,
  Input,
  InputContainer,
  InputLink,
  InputWrapper,
  EnteredText,
} from './styled';
import useBasket from '../../../hooks/use-basket';
import { selectDiscountErrorText } from '../../../store/selectors/basket';
import ActionType from '../../../store/action-type';

function Promo() {
  const error = useSelector(selectDiscountErrorText);
  const { promoCodeEntered } = useSelector((state) => state.basket);
  const { setPromoCode } = useBasket();
  const dispatch = useDispatch();

  const isEnteredPromoCode = promoCodeEntered && promoCodeEntered !== '';
  const isLinkVisible = !isEnteredPromoCode;

  const intl = useIntl();
  const [enteredText, setText] = useState(null);
  const [visibleLink, setVisibleLink] = useState(isLinkVisible);
  const isError = error !== '';

  useEffect(() => {
    const timer = setTimeout(() => {
      if (enteredText) {
        dispatch({
          type: ActionType.Basket.UpdateType,
          payload: { promoCodeEntered: enteredText },
        });
      }
    }, 500);

    return () => clearTimeout(timer);
  }, [enteredText]);

  useEffect(() => {
    setText(promoCodeEntered);
  }, [promoCodeEntered]);

  return visibleLink ? (
    <EnteredText onClick={() => setVisibleLink(false)}>
      {intl.formatMessage({ id: 'basket.all.applypromo' })}
    </EnteredText>
  ) : (
    <InputWrapper>
      <InputContainer>
        <Input
          value={enteredText}
          placeholder={intl.formatMessage({ id: 'basket.all.entercode' })}
          type="text"
          onChange={(e) => setText(e.target.value)}
          onBlur={(e) => {
            dispatch({
              type: ActionType.Basket.UpdateType,
              payload: { promoCodeEntered: e.target.value },
            });
          }}
          error={isError}
          link
        />
        <InputLink onClick={() => setPromoCode(enteredText)}>
          {intl.formatMessage({ id: 'basket.all.apply' })}
        </InputLink>
      </InputContainer>
      {isError && <ErrorText>{intl.formatMessage({ id: error })}</ErrorText>}
    </InputWrapper>
  );
}

export default Promo;
