import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { CartridgeBlock, CheckboxCartridgeWrap } from './styled';
import useBasket from '../../../hooks/use-basket';
import Checkbox from '../../../ui/checkbox';
import { Text } from '../styled';
import Context from './Context';

function DualBlock({ diffText, isAlternated }) {
  const { id, mobile } = useContext(Context);
  const { setItemCartridgeState } = useBasket();

  return (
    <CartridgeBlock>
      {mobile ? (
        <CheckboxCartridgeWrap>
          <Checkbox
            checked={isAlternated}
            onChange={(value) => setItemCartridgeState({ id, value })}
          >
            <FormattedMessage id="basket.item.autoadd_short" />
          </Checkbox>
          <Text>{diffText}</Text>
        </CheckboxCartridgeWrap>
      ) : (
        <Checkbox
          checked={isAlternated}
          onChange={(value) => setItemCartridgeState({ id, value })}
        >
          <FormattedMessage
            id="basket.item.autoadd"
            values={{ val: diffText }}
          />
        </Checkbox>
      )}
    </CartridgeBlock>
  );
}

DualBlock.propTypes = {
  diffText: PropTypes.string.isRequired,
  isAlternated: PropTypes.bool.isRequired,
};

export default DualBlock;
