import React from 'react';
import { FormattedMessage, FormattedPlural, useIntl } from 'react-intl';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import ActionType from '../../store/action-type';
import closeImg from '../../images/basket/close.svg';
import { calculateItemsCount } from '../../utils/api/basket';
import {
  Container,
  Header,
  HeaderTitle,
  HeaderTitleItem,
  CloseButton,
  Content,
  PriceBlock,
  PriceText,
  ShippingText,
  Divider,
  ExtraDiscountText,
  NoItems,
  ShopButton,
} from './styled';
import Promo from './Promo/index';
import PricePromo from './PricePromo/index';
import RowItem from './RowItem/index';
import useI18nNavigation from '../../hooks/use-i18n-navigation';
import useBasket from '../../hooks/use-basket';
import usePathName from '../../hooks/use-path-name';
import formatPrice from '../../utils/format-price';
import { ButtonBig } from '../../ui/buttonDefault';
import preparePlural from '../../utils/intl';
import Additional from './Additional';
import { CoolPrivilegeCounter } from './CoolPrivilege/index';
import ProductType from '../../domain/product-type';
import useLocalizedCurrency from '../../hooks/use-localized-currency';

const SHOP_PATHNAME = 'shop';
const SHOP_HREF = `/${SHOP_PATHNAME}`;

/* eslint-disable prefer-destructuring */
const GATSBY_SHIPPING_DISC_LOW = process.env.GATSBY_SHIPPING_DISC_LOW;
const GATSBY_SHIPPING_DISC_AMOUNT = process.env.GATSBY_SHIPPING_DISC_AMOUNT;
/* eslint-enable prefer-destructuring */

/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/interactive-supports-focus */
function Basket({ checkout }) {
  const intl = useIntl();
  const { setPromoCode } = useBasket();
  const navigate = useI18nNavigation();
  const dispatch = useDispatch();
  const pathName = usePathName();

  const {
    list: items,
    visible,
    cartSubtotal,
    cartDiscount,
    promoCodeFinal,
    taxAmount,
    totalPriceWithDiscTaxed,
    vatValue,
  } = useSelector((state) => state.basket);

  const promoCode = promoCodeFinal;
  let promoDiscount = cartDiscount;
  if (items.length === 0) {
    promoDiscount = 0;
  }
  const isFinalPromoCode = promoCodeFinal && promoCodeFinal !== '';

  const itemsToShow = items.filter(
    (item) => item.productType !== ProductType.coolPrivilege
  );

  const itemsVariantsCount = itemsToShow.length;

  const itemsCount = calculateItemsCount(items);
  const currency = useLocalizedCurrency();
  let disabled = false;

  if (itemsCount === 0) disabled = true;

  const onClose = () => {
    dispatch({ type: ActionType.Basket.Visible, payload: false });
  };

  const onCheckout = () => {
    onClose();
    navigate('/checkout');
  };

  const onShopClick = (event) => {
    event.preventDefault();
    onClose();
    navigate(SHOP_HREF);
  };

  const onHidePromo = () => {
    setPromoCode('');
    dispatch({
      type: ActionType.Basket.UpdateType,
      payload: { promoCodeEntered: '' },
    });
  };

  return (
    <Container visible={visible}>
      <Header>
        <div>
          <HeaderTitle>
            {intl.formatMessage({ id: 'basket.all.title' })}
          </HeaderTitle>
          <HeaderTitleItem>
            {`${itemsCount} `}
            <FormattedPlural
              /* eslint-disable-next-line react/jsx-props-no-spreading */
              {...preparePlural(intl, 'basket.all.item')}
              value={itemsCount}
            />
          </HeaderTitleItem>
        </div>
        <div>
          <CloseButton src={closeImg} onClick={onClose} alt="close" />
        </div>
      </Header>
      <Content>
        {itemsToShow.map((item, i) => (
          <RowItem
            last={i + 1 === itemsVariantsCount}
            iter={i}
            key={item.id}
            item={item}
          />
        ))}
      </Content>
      {itemsToShow.length === 0 && (
        <NoItems>
          {intl.formatMessage({ id: 'basket.no.items' })}.
          {pathName !== SHOP_PATHNAME && (
            <>
              &nbsp;
              <ShopButton onClick={onShopClick} href={`/${SHOP_HREF}`}>
                {intl.formatMessage({ id: 'basket.shop.link' })}.
              </ShopButton>
            </>
          )}
        </NoItems>
      )}
      <Divider />
      {isFinalPromoCode && (
        <PricePromo
          onClick={onHidePromo}
          discountValue={`-${formatPrice(promoDiscount)} ${currency}`}
          promoCode={promoCode}
        />
      )}
      <CoolPrivilegeCounter />
      {taxAmount && vatValue ? (
        <PriceBlock>
          <PriceText>
            {intl.formatMessage({ id: 'basket.country.tax' })} ({vatValue}%)
          </PriceText>
          <PriceText>{`${formatPrice(taxAmount)} ${currency}`}</PriceText>
        </PriceBlock>
      ) : null}
      <PriceBlock>
        <PriceText>
          {intl.formatMessage({ id: 'basket.all.subtotal' })}
        </PriceText>
        <PriceText>{`${formatPrice(
          totalPriceWithDiscTaxed || cartSubtotal
        )} ${currency}`}</PriceText>
      </PriceBlock>
      {checkout && (
        <ButtonBig onClick={onCheckout} disabled={disabled} extended full>
          {intl.formatMessage({ id: 'basket.all.checkout' })}
        </ButtonBig>
      )}
      <ShippingText>
        {intl.formatMessage({ id: 'basket.all.shipping_desc' })}
      </ShippingText>
      {cartSubtotal < GATSBY_SHIPPING_DISC_LOW && (
        <ExtraDiscountText>
          <FormattedMessage
            id="basket.extra_discount"
            values={{
              amount: GATSBY_SHIPPING_DISC_AMOUNT,
              currency,
              low_price: GATSBY_SHIPPING_DISC_LOW,
            }}
          />
        </ExtraDiscountText>
      )}
      <Divider />
      {!isFinalPromoCode && <Promo />}
      <Additional />
    </Container>
  );
}
/* eslint-enable jsx-a11y/click-events-have-key-events */
/* eslint-enable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-enable jsx-a11y/interactive-supports-focus */

Basket.propTypes = {
  checkout: PropTypes.bool,
};

Basket.defaultProps = {
  checkout: false,
};

export default Basket;
