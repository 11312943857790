import React, { useState, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import union from 'lodash/union';
import {
  Bottom,
  Top,
  Wrap,
  Button,
  TopLeft,
  TopRight,
  Title,
  Desc,
  ArrowLeft,
  ArrowRight,
  Main,
} from './styled';
import { getReadablePrice } from '../../../domain/items-list';
import {
  getItemAdditionalGoods,
  getItemDescription,
  getItemName,
  getItemPreviewImage,
  getItemPrice,
  getItemRemaindersQuantity,
  getItems,
} from '../../../utils/api/items';
import useLocalizedCurrency from '../../../hooks/use-localized-currency';
import useBasket from '../../../hooks/use-basket';
import { colors } from '../../../ui/common';

const sliderAnim = 3000;
const sliderTimeout = 10000;

function Arrow() {
  return (
    <svg
      width="25"
      height="8"
      viewBox="0 0 25 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.646988 4.35355C0.451726 4.15829 0.451726 3.84171 0.646988 3.64645L3.82897 0.464466C4.02423 0.269204 4.34081 0.269204 4.53608 0.464466C4.73134 0.659728 4.73134 0.976311 4.53608 1.17157L1.70765 4L4.53608 6.82843C4.73134 7.02369 4.73134 7.34027 4.53608 7.53553C4.34081 7.7308 4.02423 7.7308 3.82897 7.53553L0.646988 4.35355ZM24.5684 4.5H1.00054V3.5H24.5684V4.5Z"
        fill={colors.color05}
      />
    </svg>
  );
}

const getGoodsItems = (items) => {
  const allItems = getItems();

  /* merge all goods */
  let allGoods = [];

  items.forEach((v) => {
    const item = allItems.find((value) => value.id === v.id);

    if (item) {
      const goods = getItemAdditionalGoods(item);

      allGoods = union(allGoods, goods);
    }
  });

  /* get items for goods */
  const goodsItems = [];

  allGoods.forEach((id) => {
    const item = allItems.find((value) => value.id === id);
    const founded = items.find((value) => value.id === id);

    if (item && getItemRemaindersQuantity(item) > 0 && !founded) {
      goodsItems.push(item);
    }
  });

  return goodsItems;
};

const getTimeMs = () => new Date().getTime();

function Additional() {
  const { list: items } = useSelector((state) => state.basket);
  const { addItem } = useBasket();

  const goodsItems = getGoodsItems(items);

  const ref = useRef();
  ref.current = goodsItems;

  /* slider */
  const [index, setIndex] = useState(0);
  const [tick, setLastTick] = useState(0);

  const getSelectedItem = () => ref.current[index];
  const getMaxIndex = () => ref.current.length - 1;

  /* anim */
  const isPlayAnim = () => tick < getTimeMs();
  const setAnimTimeout = () => setLastTick(getTimeMs() + sliderTimeout);

  /* handlers */
  const onPrev = (clicked) => {
    if (clicked) setAnimTimeout();

    setIndex((v) => {
      const limit = getMaxIndex();

      let i = v - 1;

      if (i < 0) i = limit;

      return i;
    });
  };

  const onNext = (clicked) => {
    if (clicked) setAnimTimeout();

    setIndex((v) => {
      const limit = getMaxIndex();

      let i = v + 1;

      if (i > limit) i = 0;

      return i;
    });
  };

  useEffect(() => {
    const interval = setInterval(() => {
      if (!isPlayAnim()) return;

      onNext();
    }, sliderAnim);

    return () => clearInterval(interval);
  }, [tick]);

  // Reset slider after reload page
  useEffect(() => {
    setLastTick(0);
  }, []);

  // Check if index is not exists.
  useEffect(() => {
    const limit = getMaxIndex();

    if (index > limit) setIndex(0);
  });

  const currency = useLocalizedCurrency();

  /* item */
  const item = getSelectedItem();

  if (!item) return null;

  const title = getItemName(item);
  const desc = getItemDescription(item);
  const price = getItemPrice(item);
  const img = getItemPreviewImage(item);

  const priceText = getReadablePrice({ amount: price, currency });

  const onAddItem = () => addItem(item);

  return (
    <Main>
      <Wrap>
        <Top>
          <TopLeft>
            <img src={img} alt="preview" />
          </TopLeft>
          <TopRight>
            <Title>{title}</Title>
            <Desc>
              <FormattedMessage id={desc} />
            </Desc>
          </TopRight>
        </Top>
        <Bottom>
          <Button onClick={onAddItem}>
            <FormattedMessage
              id="basket.additional.add_to_cart"
              values={{ val: priceText }}
            />
          </Button>
        </Bottom>
      </Wrap>
      <ArrowLeft onClick={() => onPrev(true)}>
        <Arrow />
      </ArrowLeft>
      <ArrowRight onClick={() => onNext(true)}>
        <Arrow />
      </ArrowRight>
    </Main>
  );
}

export default Additional;
