import React from 'react';
import PropTypes from 'prop-types';
import removePromoImg from '../../../images/basket/remove-promo.svg';
import { Text, Amount, Img, Wrap } from './styled';

function PricePromo({ onClick, discountValue, promoCode }) {
  return (
    <Wrap>
      <Img onClick={onClick} src={removePromoImg} alt="remove" />
      <Text>{promoCode}</Text>
      <Amount>{discountValue}</Amount>
    </Wrap>
  );
}

PricePromo.propTypes = {
  onClick: PropTypes.func.isRequired,
  promoCode: PropTypes.string.isRequired,
  discountValue: PropTypes.string.isRequired,
};

export default PricePromo;
