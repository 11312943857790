import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { FormattedMessage, useIntl } from 'react-intl';
import ButtonCounter from '../../../ui/button-counter';
import {
  CounterFlexContainer,
  CounterText,
  CounterPrice,
  CoolPrivilegeLinkText,
  CheckboxCoolPrivilegeWrap,
  CheckBoxCoolPrivilegeText,
} from './styled';
import formatPrice from '../../../utils/format-price';
import { StyledCheckboxWrap } from '../../../ui/checkbox';
import ProductType from '../../../domain/product-type';
import LocalizedLink from '../../localized-link';
import useLocalizedCurrency from '../../../hooks/use-localized-currency';
import useBasket from '../../../hooks/use-basket';

export const getCoolPrivilegeItem = () =>
  useSelector((state) => {
    if (!state.items || !state.items.list) {
      return null;
    }
    return state.items.list.find((item) => {
      if (!item.product || !item.product.types) {
        return null;
      }
      return item.product.types.find(
        (el) => el.code === ProductType.coolPrivilege
      );
    });
  });

export const useCoolPrivilegeStuff = () => {
  const coolPrivilegeItem = getCoolPrivilegeItem();
  const price =
    coolPrivilegeItem && coolPrivilegeItem.country_prices[0]
      ? coolPrivilegeItem.country_prices[0].price.amount
      : 0;
  const currency = useLocalizedCurrency();

  return {
    coolPrivilegeItem,
    currency,
    price,
  };
};

export function CoolPrivilegeCounter() {
  const intl = useIntl();
  const { removeItem, setItemCount, addItem } = useBasket();
  const basketItems = useSelector((state) => state.basket.list);
  const coolPrivilegeInBasket = basketItems.find(
    (item) => item.productType === ProductType.coolPrivilege
  );
  const coolPrivilegesLimit = basketItems
    .filter(
      (item) =>
        item.productType === ProductType.device ||
        item.productType === ProductType.package
    )
    .reduce((count, item) => count + item.count, 0);

  const { coolPrivilegeItem, currency } = useCoolPrivilegeStuff();
  const count = coolPrivilegeInBasket ? coolPrivilegeInBasket.count : 0;
  const price = coolPrivilegeInBasket ? coolPrivilegeInBasket.price : 0;
  const total = `${formatPrice(count * price)} ${currency}`;

  useEffect(() => {
    if (count > coolPrivilegesLimit) {
      setItemCount({
        id: coolPrivilegeItem.id,
        count: coolPrivilegesLimit,
      }).then(() => {
        if (count === 0 && coolPrivilegeInBasket)
          removeItem(coolPrivilegeInBasket);
      });
    }
  }, [coolPrivilegesLimit]);

  const updateCount = (newCount) => {
    if (!coolPrivilegeInBasket) {
      addItem(coolPrivilegeItem, false);
      return;
    }

    if (newCount > coolPrivilegesLimit) return;

    setItemCount({ id: coolPrivilegeItem.id, count: newCount });
  };

  return (
    <CounterFlexContainer>
      <CounterText>
        <FormattedMessage id="basket.cool_privilege" />{' '}
        <CoolPrivilegeLinkText>
          <LocalizedLink to="cool-privilege">
            {intl.formatMessage({ id: 'basket.cool_privilege.link' })}
          </LocalizedLink>
        </CoolPrivilegeLinkText>
      </CounterText>
      <ButtonCounter
        count={count}
        onUpdateCount={(newCount) => updateCount(newCount)}
        minimumCount={0}
        mobile
      />
      <CounterPrice>{total}</CounterPrice>
    </CounterFlexContainer>
  );
}

export const CoolPrivilegeCheckBox = React.forwardRef((props, ref) => {
  const [hasCoolPrivilege, setCoolPrivilege] = useState(false);
  const { currency, price } = useCoolPrivilegeStuff();
  const intl = useIntl();
  // eslint-disable-next-line react/prop-types
  const { onChange, productName, productPrice, chillBlueTheme } = props;

  const handleChange = () => {
    onChange({ price: productPrice, productName });
    setCoolPrivilege(!hasCoolPrivilege);
  };

  return (
    <CheckboxCoolPrivilegeWrap>
      <StyledCheckboxWrap
        chillBlueTheme={chillBlueTheme}
        checked={hasCoolPrivilege}
      />
      <CheckBoxCoolPrivilegeText chillBlueTheme={chillBlueTheme}>
        <FormattedMessage id="basket.cool_privilege" />
        {`(+${price} ${currency}) `}
        <CoolPrivilegeLinkText>
          <LocalizedLink to="cool-privilege">
            {intl.formatMessage({ id: 'basket.cool_privilege.link' })}
          </LocalizedLink>
        </CoolPrivilegeLinkText>
      </CheckBoxCoolPrivilegeText>
      <input
        hidden
        ref={ref}
        type="checkbox"
        onChange={handleChange}
        checked={hasCoolPrivilege}
      />
    </CheckboxCoolPrivilegeWrap>
  );
});
