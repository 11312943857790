import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import deleteImg from '../../../images/basket/delete.svg';
import { isItemLeftCheck } from '../../../utils/api/basket';
import {
  ButtonDelete,
  ContentItem,
  ContentItemCenter,
  ContentItemLeft,
  ContentItemRight,
  ContentItemWrap,
  DividerItem,
} from './styled';
import { Text, Title } from '../styled';
import useWindowSize from '../../../utils/useWindowSize';
import { sizes } from '../../../ui/common';
import NotAvailable from './NotAvailable';
import DualBlock from './DualBlock';
import BottomBlock from './BottomBlock';
import Context from './Context';
import ItemLeft from './ItemLeft';
import { notifyItems } from './data';
import Notify from './Notify';
import ProductType from '../../../domain/product-type';
import useLocalizedCurrency from '../../../hooks/use-localized-currency';
import useBasket from '../../../hooks/use-basket';

function RowItem({ iter, item, last }) {
  const intl = useIntl();
  const {
    count,
    productType,
    name,
    img,
    price,
    id,
    dual,
    isAlternated,
    difference = 0,
    attributes,
    availableQuantity,
    items,
  } = item;
  const { removeItem } = useBasket();

  const size = useWindowSize();
  const realCount = count > availableQuantity ? availableQuantity : count;
  const totalPrice = (price * realCount).toFixed(2);
  const finalDifference = difference.toFixed(2);
  const { plug, color } = attributes;
  const mobile = size.width < sizes.tablet;
  const currency = useLocalizedCurrency();
  const diffText = `+${finalDifference} ${currency}`;
  const priceText = `${totalPrice} ${currency}`;
  const isItemLeft = isItemLeftCheck(item);
  const available = availableQuantity > 0;
  const noAvailableText = `${name} ${color || ''}`;
  const isNotify = notifyItems.includes(name);
  const isCoolPrivilege = productType === ProductType.coolPrivilege;

  let dualAvailable = false;

  if (dual && items[1]) {
    const targetPackage = items[1];

    if (targetPackage.availableQuantity > 0) dualAvailable = true;
  }

  // TODO Wrap with useMemo()?
  // eslint-disable-next-line react/jsx-no-constructed-context-values
  const state = {
    id,
    mobile,
    item,
    availableQuantity,
    count,
    realCount,
    isItemLeft,
  };

  return (
    <Context.Provider value={state}>
      {!isCoolPrivilege &&
        (available ? (
          <>
            <ContentItemWrap>
              <ContentItem iter={iter}>
                <ContentItemLeft>
                  <img src={img} alt="preview" />
                </ContentItemLeft>
                <ContentItemCenter>
                  <Title error={isItemLeft}>{name}</Title>
                  {plug && (
                    <Text>{`${intl.formatMessage({
                      id: 'basket.item.plug',
                    })} ${plug}`}</Text>
                  )}
                  {color && (
                    <Text>{`${intl.formatMessage({
                      id: 'basket.item.color',
                    })} ${color}`}</Text>
                  )}
                </ContentItemCenter>
                <ContentItemRight>
                  <ButtonDelete
                    src={deleteImg}
                    onClick={() => removeItem(item)}
                    alt="delete"
                  />
                </ContentItemRight>
              </ContentItem>
              <BottomBlock priceText={priceText} />
              {isNotify && <Notify />}
              {dualAvailable && (
                <DualBlock diffText={diffText} isAlternated={isAlternated} />
              )}
              {isItemLeft && <ItemLeft />}
            </ContentItemWrap>
            {last === false && <DividerItem />}
          </>
        ) : (
          <NotAvailable value={noAvailableText} />
        ))}
    </Context.Provider>
  );
}

RowItem.propTypes = {
  iter: PropTypes.number.isRequired,
  item: PropTypes.objectOf(PropTypes.any).isRequired,
  last: PropTypes.bool.isRequired,
};

export default RowItem;
