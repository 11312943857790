const PLURAL_SUBKEYS = ['zero', 'one', 'two', 'few', 'many', 'other'];

function preparePlural(intl, key) {
  const pluralObj = {};
  PLURAL_SUBKEYS.forEach((el) => {
    const val = intl.messages[`${key}.${el}`];
    if (val === undefined) {
      return;
    }
    pluralObj[el] = val;
  });
  return pluralObj;
}

export default preparePlural;
