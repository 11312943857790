import React from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import { colors } from '../../../ui/common';

const NotifyText = styled.div`
  font-size: 13px;
  color: ${colors.color01};
  user-select: none;
`;

function Notify() {
  return (
    <>
      <br />
      <NotifyText>
        <FormattedMessage
          id="basket.notify.link"
          values={{
            // eslint-disable-next-line react/no-unstable-nested-components
            a: (chunks) => (
              <a href="https://support.evapolar.com/hc/en-us/articles/360017008399-How-to-choose-a-cartridge-for-your-Evapolar">
                {chunks}
              </a>
            ),
          }}
        />
      </NotifyText>
    </>
  );
}

export default Notify;
