import React from 'react';
import PropTypes from 'prop-types';
import { useIntl, FormattedMessage, FormattedPlural } from 'react-intl';
import preparePlural from '../../../../utils/intl';

function Stock({ value: count }) {
  const intl = useIntl();

  return count === 0 ? (
    <FormattedMessage id="basket.out_of_stock" />
  ) : (
    <FormattedMessage
      id="basket.only_left"
      values={{
        val: count,
        val2: (
          <FormattedPlural
            /* eslint-disable-next-line react/jsx-props-no-spreading */
            {...preparePlural(intl, 'basket.cart.item')}
            value={count}
          />
        ),
      }}
    />
  );
}

Stock.propTypes = {
  value: PropTypes.number.isRequired,
};

export default Stock;
