import styled from 'styled-components';
import { colors } from '../../../ui/common';

export const EnteredText = styled.div`
  margin-top: 24px;
  margin-bottom: 60px;
  text-decoration: underline;
  opacity: 0.8;
  color: ${colors.color01};
  cursor: pointer;
`;

export const InputWrapper = styled.div`
  margin-top: 20px;
  margin-bottom: 60px;
`;

export const InputContainer = styled.div`
  display: flex;
  position: relative;
  overflow: hidden;
  width: 260px;
  height: 38px;
  align-items: center;
`;

const inputOpacityHEX = '66';

export const Input = styled.input`
  outline: none;
  height: 100%;
  width: 100%;
  font-size: 14px;
  border: 1px solid
    ${(props) =>
      props.error ? colors.error : `${colors.color05}${inputOpacityHEX}`};
  box-sizing: border-box;
  border-radius: 4px;
  ${(props) =>
    props.link ? 'padding: 10px 66px 10px 12px' : 'padding: 10px 12px 10px'};

  &:hover,
  &:focus {
    border: 1px solid
      ${(props) => (props.error ? colors.error : colors.color05)};
  }
`;

export const InputLink = styled.div`
  position: absolute;
  top: 50%;
  right: 15px;
  transform: translateY(-50%);
  text-decoration: underline;
  color: ${colors.color01};
  opacity: 0.8;
  font-weight: 500;
  cursor: pointer;
`;

export const ErrorText = styled.div`
  margin-top: 10px;
  font-size: 14px;
  color: ${colors.error};
`;
