import styled, { css } from 'styled-components';
import { colors } from './common';

export const button = css`
  display: inline-block;
  box-sizing: border-box;
  white-space: nowrap;
  height: 52px;
  line-height: 48px;
  border-radius: 4px;
  min-width: 158px;
  font-weight: 500;
  font-size: 14px;
  padding: 0 24px;
  text-align: center;
  border: 1px solid transparent;
  text-decoration: none;
  cursor: pointer;
  outline: none;
`;

// TODO DRY
export const primary = css`
  ${button};
  background-color: ${colors.color01};
  color: #fff;
  font-weight: 600;
  padding: 0 24px;
  outline: none;

  transition: 0.25s ease-out;
  &:hover {
    background-color: ${colors.color08};
    border-color: ${colors.color08};
  }
  &:active {
    background-color: #a0d9f3;
    border-color: #a0d9f3;
  }
  &:disabled {
    background-color: #828282;
    border-color: #828282;
    cursor: not-allowed;
  }
`;

// TODO DRY
export const secondary = css`
  ${button};
  color: ${colors.color05};
  border-color: ${colors.color05};
  background-color: transparent;
  transition: 0.25s ease-out;
  &:hover {
    background-color: ${colors.color05};
    border-color: ${colors.color05};
    color: #fff;
  }
  &:active {
    background-color: ${colors.color08};
    border-color: ${colors.color08};
    color: #fff;
  }
  &:disabled {
    background-color: transparent;
    color: #7c7c7c;
    border-color: #7c7c7c;
  }
`;

export const Button = styled.button.attrs(() => ({
  type: 'button',
}))`
  ${primary};
`;

export const buttonParams = css`
  height: 70px;
  padding: ${({ extended }) => (extended ? '0 63px' : '0 52px')};
  ${({ full }) => full && 'width: 100%;'}
`;

export const ButtonBig = styled.button.attrs(() => ({
  type: 'button',
}))`
  ${primary};
  ${buttonParams};
`;

export const ButtonBigSecondary = styled.button.attrs(() => ({
  type: 'button',
}))`
  ${secondary};
  ${buttonParams};
`;
