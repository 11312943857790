import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import {
  ContentControlCenter,
  ContentControlLeft,
  ContentControlRight,
  ContentItemBottom,
  ControlText,
  ControlWrapText,
} from './styled';
import ButtonCounter from '../../../ui/button-counter';
import Context from './Context';
import useBasket from '../../../hooks/use-basket';

function BottomBlock({ priceText }) {
  const { id, mobile, availableQuantity, count, oldCount, isItemLeft } =
    useContext(Context);
  const { setItemCount } = useBasket();

  return (
    <ContentItemBottom>
      <ContentControlLeft />
      {mobile ? (
        <>
          <ContentControlCenter>
            <ControlWrapText>
              <FormattedMessage id="basket.item.price" />
            </ControlWrapText>
            <ControlText>{priceText}</ControlText>
          </ContentControlCenter>
          <ContentControlRight>
            <ButtonCounter
              count={count}
              onUpdateCount={(newCount) => {
                setItemCount({ id, count: newCount });
              }}
              mobile={mobile}
              error={isItemLeft}
              oldCount={availableQuantity >= count ? null : oldCount}
            />
          </ContentControlRight>
        </>
      ) : (
        <>
          <ContentControlCenter>
            <ButtonCounter
              count={count}
              onUpdateCount={(newCount) => {
                setItemCount({ id, count: newCount });
              }}
              mobile={mobile}
              error={isItemLeft}
              oldCount={availableQuantity >= count ? null : oldCount}
            />
          </ContentControlCenter>
          <ContentControlRight>
            <ControlWrapText>
              <FormattedMessage
                id="basket.item.price_numb"
                values={{ val: priceText }}
              />
            </ControlWrapText>
          </ContentControlRight>
        </>
      )}
    </ContentItemBottom>
  );
}

BottomBlock.propTypes = {
  priceText: PropTypes.string.isRequired,
};

export default BottomBlock;
