import styled from 'styled-components';
import { colors } from '../../../../ui/common';
import { Link as CustomLink } from '../styled';

export const Main = styled.div`
  position: relative;
`;

export const Root = styled.div`
  position: absolute;
  top: -44px;
  width: 100%;
`;

export const Wrap = styled.div`
  display: flex;
  position: relative;
  justify-content: space-between;
  align-items: center;
  padding: 15px 20px;
  background: #ffffff;
  box-shadow: 0px 7px 20px rgba(0, 0, 0, 0.05);
  border-radius: 4px;
  z-index: 10;

  &::before {
    content: '';
    position: absolute;
    left: calc(50% - 20px / 2);
    top: -10px;
    width: 0;
    height: 0;
    border-left: 20px solid transparent;
    border-right: 20px solid transparent;
    border-bottom: 20px solid #fff;
  }
`;

export const Text = styled.div`
  font-weight: normal;
  font-size: 12px;
  color: ${colors.color01};
`;

export const Link = styled(CustomLink)`
  margin-top: 0px;
  font-size: 12px;
  text-align: left;
`;
