import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { colors, colorsBar } from './common';

const CheckboxContainer = styled.div`
  display: inline-block;
  vertical-align: middle;
  margin-right: 10px;
`;

const Icon = styled.div`
  text-align: center;
`;

const HiddenCheckbox = styled.input.attrs({ type: 'checkbox' })`
  border: 0;
  clip: rect(0 0 0 0);
  clippath: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
  left: 0;
  top: 0;
`;

const StyledCheckbox = styled.div`
  display: inline-block;
  width: 19px;
  height: 19px;
  border-radius: 4px;
  transition: all 150ms;
  border: 1px solid ${colors.color05};
`;

/* eslint-disable react/prop-types */
export function StyledCheckboxWrap({ checked, chillBlueTheme }) {
  let bg;

  if (checked && chillBlueTheme) {
    bg = colorsBar.blue;
  }
  if (checked && !chillBlueTheme) {
    bg = colors.color05;
  }
  if (!checked) {
    bg = colors.white;
  }

  return (
    <StyledCheckbox checked={checked} style={{ backgroundColor: bg }}>
      <Icon>
        <svg
          width="10"
          height="8"
          viewBox="0 0 10 8"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M1 4.75L3.18182 7L9 1"
            stroke="white"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </Icon>
    </StyledCheckbox>
  );
}

/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-props-no-spreading */
function CheckboxButton({ className, checked, ...props }) {
  return (
    <CheckboxContainer className={className}>
      <HiddenCheckbox checked={checked} {...props} />
      <StyledCheckboxWrap checked={checked} />
    </CheckboxContainer>
  );
}
/* eslint-enable react/prop-types */
/* eslint-enable react/jsx-props-no-spreading */

const Text = styled.span`
  font-size: 13px;
  color: ${colors.color01};
  //margin-left: 8px;
  user-select: none;
`;

const Wrap = styled.label`
  display: flex;
  justify-content: center;
  align-items: center;
`;

function Checkbox({ checked, onChange, children, subChildren }) {
  return (
    <>
      {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
      <Wrap>
        <CheckboxButton
          checked={checked}
          onChange={(event) => onChange(event.target.checked)}
        />
        <span>
          <Text>{children}</Text>
          {subChildren && subChildren}
        </span>
      </Wrap>
    </>
  );
}

Checkbox.propTypes = {
  checked: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  children: PropTypes.node,
  subChildren: PropTypes.node,
};

Checkbox.defaultProps = {
  checked: false,
  children: '',
  subChildren: null,
};

export default Checkbox;
