import Big from 'big.js';
import { isPackage, isDevice } from '../utils/slug-checks';
import PLUGS from './plugs';
import COLORS from './colors';
import ItemType from './item-type';
import { getItemRemaindersQuantity } from '../utils/api/items';

const findById = (list, id) => list.find((item) => item.id === id);

const getPackagesAndDevices = (list, productName) =>
  list.reduce(
    (acc, item) => {
      if (!item.product.name.startsWith(productName)) {
        return acc;
      }

      const { slug } = item.product;
      if (isPackage(slug)) {
        return { ...acc, packages: acc.packages.set(item.id, item.parts) };
      }
      if (isDevice(slug)) {
        return { ...acc, devices: acc.devices.concat(item) };
      }
      return acc;
    },
    { packages: new Map(), devices: [] }
  );

const getOrphanDevices = (packages, devices) => {
  const devicesIdsInPackages = [...packages.values()].reduce(
    (acc, v) => acc.concat(v),
    []
  );

  return devices.filter((item) => !devicesIdsInPackages.includes(item.id));
};

const getChooseable = (list, devicesIds, packages) =>
  [...packages.entries()].reduce(
    (acc, [packageId, partsIds]) =>
      partsIds.some((id) => devicesIds.includes(id))
        ? [
            ...acc,
            [
              findById(list, packageId),
              partsIds.map((id) => findById(list, id)),
            ],
          ]
        : acc,
    []
  );

const isAvailable = (item) => getItemRemaindersQuantity(item) > 0;

const filterByAvailability = (devices, orphanDevices, chooseable) => {
  const filteredOrphanDevicesIds = new Set(
    orphanDevices.reduce(
      (acc, item) => (isAvailable(item) ? acc.concat(item.id) : acc),
      []
    )
  );
  const filteredChooseableIds = new Set();
  const packagesOnlyIds = new Set();
  chooseable.forEach(([devicePackage, parts]) => {
    // There is an assumption that there is only one device in the package!
    const device = devices.find(({ id }) =>
      parts.some(({ id: partId }) => id === partId)
    );
    const availableDevice = isAvailable(device);
    if (isAvailable(devicePackage)) {
      if (availableDevice) {
        filteredChooseableIds.add(devicePackage.id);
      } else {
        packagesOnlyIds.add(devicePackage.id);
      }
    } else if (availableDevice) {
      filteredOrphanDevicesIds.add(device.id);
    }
  });

  return {
    orphanDevices: [...filteredOrphanDevicesIds].map((id) =>
      devices.find((item) => item.id === id)
    ),
    packagesOnly: [...packagesOnlyIds].map((id) =>
      chooseable.find((entry) => entry[0].id === id)
    ),
    chooseable: [...filteredChooseableIds].map((id) =>
      chooseable.find((entry) => entry[0].id === id)
    ),
  };
};

const getColorsMapping = (devices) =>
  devices.reduce((acc, item) => {
    item.gallery.forEach((v) => {
      if (v.choice) {
        acc.set(v.choice.short_name, v.choice.title);
      }
    });
    return acc;
  }, new Map());

const getPrice = (item) => {
  if (process.env.GATSBY_SHOW_TAX_PRICES === 'true') {
    return item.country_prices[0].taxed_price;
  }
  return item.country_prices[0].price;
};

// TODO Use https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/NumberFormat ?
const concatAmountAndCurrency = (amount, currency) => `${amount} ${currency}`;

export const getReadablePrice = ({ amount, currency }) =>
  concatAmountAndCurrency(Big(amount).toFixed(2), currency);

const getItemInfo = (item) => ({
  color: item.attributes.color || null,
  plug: item.attributes.plug || null,
  price: getPrice(item),
  id: item.id,
});

const getPackageInfo = (devicesIds, [devicePackage, parts]) => {
  const { price, id: packageId, ...rest } = getItemInfo(devicePackage);
  const { amount, currency } = price;
  const device = parts.find(({ id }) => devicesIds.includes(id));
  const bigPartsPrice = parts.reduce(
    (acc, part) => acc.plus(getPrice(part).amount),
    Big(0)
  );
  const bigPricesDiff = bigPartsPrice.minus(Big(amount));

  return {
    ...rest,
    deviceId: device.id,
    packageId,
    packagePrice: getReadablePrice(price),
    packagePriceData: price,
    devicePrice: getReadablePrice(getPrice(device)),
    devicePriceData: getPrice(device),
    pricesDiff: concatAmountAndCurrency(bigPricesDiff.toFixed(2), currency),
  };
};

const flatten = (kitInfo) => {
  const { orphanDevices, packagesOnly, chooseable } = kitInfo;
  return [
    ...orphanDevices.map((item) => ({ ...item, type: ItemType.OrphanDevice })),
    ...packagesOnly.map((item) => ({ ...item, type: ItemType.PackageOnly })),
    ...chooseable.map((item) => ({ ...item, type: ItemType.Chooseable })),
  ].sort(
    (a, b) =>
      COLORS.findIndex((color) => a.color === color) -
        COLORS.findIndex((color) => b.color === color) ||
      PLUGS.findIndex((plug) => a.plug === plug) -
        PLUGS.findIndex((plug) => b.plug === plug)
  );
};

export const getItemsInfo = (list, productName) => {
  const { packages, devices } = getPackagesAndDevices(list, productName);
  const devicesIds = devices.map((item) => item.id);
  const rawOrphanDevices = getOrphanDevices(packages, devices);
  const rawChooseable = getChooseable(list, devicesIds, packages);
  const filtered = filterByAvailability(
    devices,
    rawOrphanDevices,
    rawChooseable
  );

  return {
    available: flatten({
      orphanDevices: filtered.orphanDevices.map((item) => {
        const itemInfo = getItemInfo(item);
        return {
          ...itemInfo,
          deviceId: itemInfo.id,
          price: getReadablePrice(itemInfo.price),
          priceData: itemInfo.price,
        };
      }),
      packagesOnly: filtered.packagesOnly.map((entry) =>
        getPackageInfo(devicesIds, entry)
      ),
      chooseable: filtered.chooseable.map((entry) =>
        getPackageInfo(devicesIds, entry)
      ),
    }),
    colorsMapping: getColorsMapping(devices),
  };
};

const [sortColors, sortPlugs] = [COLORS, PLUGS].map(
  (order) => (v1, v2) =>
    order.findIndex((v) => v === v1) - order.findIndex((v) => v === v2)
);

export const getColors = (kitInfo) =>
  [...new Set(kitInfo.available.map((item) => item.color))].sort(sortColors);

export const getPlugs = (kitInfo) =>
  [...new Set(kitInfo.available.map((item) => item.plug))].sort(sortPlugs);
